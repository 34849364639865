<template>
  <v-container fluid>
    <QPointsTab :activeTab="'logs'" />
    <v-card class="shadow px-8  rounded-5 mt-5 pt-10 pb-5">
      <v-row class="">
        <div style="font-size: 18px;font-weight: 500; margin-left:15px">Points Log</div>
        <v-spacer></v-spacer>
        <v-col md="2">
          <div style="width: 110px; float: right">
            <v-select
                outlined
                :menu-props="{ bottom: true, offsetY: true }"
                background-color="#fff"
                filled
                dense
                v-model="perPage"
                label="Per Page"
                :items="[10, 15, 25, 50]"
                @change="loadLogs"
            ></v-select>
          </div>
        </v-col>
      </v-row>

      <v-simple-table>
        <template v-slot:default>
            <thead>
                <tr class="tr-rounded tr-neon opacity-70">
                    <th>Timestamp</th>
                    <th>Customer</th>
                    <th>Points</th>
                    <th>Remaining</th>
                    <th>Source</th>
                    <th>Expiry Date</th>
                    <th>Transaction</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(log, i) in logs" :key="i">
                    <td>{{ log.timestamp | timeStamp }}</td>
                    <td>{{ log.customer_name }}</td>
                    <td>{{ log.points }} </td>
                    <td>{{ log.points_remaining }} </td>
                    <td>{{ log.source }} </td>
                    <td>{{ log.expiry_date }} </td>
                    <td>{{ log.transaction_type == 'c'?'Credit':'Debit' }} </td>
                    <td>{{ log.status }} </td>
                </tr>
            </tbody>
        </template>
      </v-simple-table>

      <v-pagination
          v-if="totalPages > 1"
          v-model="page"
          :length="totalPages"
      ></v-pagination>
    </v-card>
  </v-container>
</template>
<script>
import QPointsTab from "@/components/QPoints/QPointsTab.vue";
export default {
  props: {},
  components: { QPointsTab },
  data() {
    return {
      logs: [],
      page: 1,
      totalPages: 1,
      perPage: 10,
      confirmModel: {
        id: null,
        title: null,
        description: null,
      },
      viewLeadId: null,
      isResponseModalVisible: false,
      isStatusModalVisible: false
    };
  },
  computed: {},
  mounted() {
    this.loadLogs();
  },
  watch: {
    page() {
      this.loadLogs();
    },
  },

  methods: {
    loadLogs() {
      this.showLoader('loading');
      this.$http.get('venues/qpoints/logs', {
        params: {
          per_page: this.perPage,
          page: this.page
        }
      }).then(response => {
        if (response.status == 200) {
          const records = response.data
          this.logs = records.data;
          this.page = records.current_page ? Number(records.current_page) : 1;
          this.totalPages = records.total_pages
        }
      }).finally(() => {
        this.hideLoader();
      })
    },
  },
};
</script>

<style scoped lang="scss">
.tr-rounded {
  th:first-child,
  td:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  th:last-child,
  td:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}

.tr-neon {
  td,
  th {
    background: #4faeaf !important;
  }

  &.opacity-70 {
    td,
    th {
      background: rgba(233, 241, 246, 0.7) !important;
    }
  }
}

.shadow {
  box-shadow: 0 4px 8px 0 rgba(70, 76, 136, 0.2) !important;
}

.v-card {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
  margin-right: 8px !important;
  margin-left: 8px !important;
}

.rounded-5 {
  border-radius: 1.25rem !important;
}

th {
  font-size: 11px !important;
}
</style>
